var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"mx-2"},[_c('h3',{staticClass:"mt-5"},[_vm._v(" "+_vm._s(_vm.$getVisibleNames("mesh.modality", false, "Modalidad de Asignaturas"))+" "),_c('AlternativeNameButton',{attrs:{"table_name":'mesh.modality',"allows_crud":_vm.allows_crud}})],1),_c('div',{staticStyle:{"display":"flex"}},[_c('div',{staticClass:"col",staticStyle:{"text-align":"left"}},[(_vm.allows_crud)?_c('ButtonMedium',{directives:[{name:"can",rawName:"v-can",value:('mesh.add_modality'),expression:"'mesh.add_modality'"}],attrs:{"tooltip_text":`Agregar ${_vm.$getVisibleNames(
            'mesh.modality',
            false,
            'Modalidad de Asignaturas'
          )}`,"text_button":`Agregar`,"click_button":() => _vm.$bvModal.show(`new-modality-modal`),"icon":'plus',"variant":'primary'}}):_vm._e()],1)]),(_vm.searchModality.length > 0)?_c('GenericBTable',{attrs:{"items":_vm.modalitiesSort,"pagination":_vm.modalities.length,"fields":_vm.modalitiesFields,"filter":_vm.input_search,"show_pagination":true,"search_filter":true,"columns_display":true,"allows_crud":_vm.allows_crud},scopedSlots:_vm._u([(_vm.allows_crud)?{key:"cell(actions)",fn:function(row){return [_c('ButtonSmall',{class:``,attrs:{"tooltip_text":`Editar ${_vm.$getVisibleNames(
            'mesh.modality',
            false,
            'Modalidad de Asignaturas'
          )}`,"click_button":() => _vm.$bvModal.show(`edit-modality-modal-${row.item.id}`),"icon":'square'}}),(
            _vm.institution &&
            _vm.institution.internal_use_id == 'duoc_uc' &&
            row.item.internal_use_id != null
          )?_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.v-secondary.auto.noninteractive",value:(
            `Este objeto es necesarios para el funcionamiento de la maleta didáctica.`
          ),expression:"\n            `Este objeto es necesarios para el funcionamiento de la maleta didáctica.`\n          ",modifiers:{"v-secondary":true,"auto":true,"noninteractive":true}}]},[_c('ButtonSmall',{attrs:{"tooltip_text":``,"disabled_button":true,"icon":'trash'}})],1):_c('ButtonSmall',{attrs:{"click_button":() => _vm.askForDeleteModality(row.item.id),"tooltip_text":`Eliminar ${_vm.$getVisibleNames(
            'mesh.modality',
            false,
            'Modalidad de Asignaturas'
          )}`,"icon":'trash'}}),_c('b-modal',{attrs:{"id":`edit-modality-modal-${row.item.id}`,"title":`Editar ${_vm.$getVisibleNames(
            'mesh.modality',
            false,
            'Modalidad de Asignaturas'
          )}`,"size":"md","hide-footer":""}},[_c('ModalityForm',{attrs:{"Modality":row.item,"Modalities":_vm.modalities,"show_title":false},on:{"updated":_vm.slotUpdatedModality}})],1)]}}:null],null,true)}):_c('div',[_c('br'),_c('strong',[_vm._v("No se a encontrado ninguna "+_vm._s(_vm.$getVisibleNames( "mesh.modality", false, "Modalidad de Asignaturas" ))+".")])])],1),_c('b-modal',{attrs:{"id":`new-modality-modal`,"hide-footer":true,"title":`Crear ${_vm.$getVisibleNames(
      'mesh.modality',
      false,
      'Modalidad de Asignaturas'
    )}`,"size":"md"}},[_c('ModalityForm',{attrs:{"Modalities":_vm.modalities},on:{"created":_vm.slotCreatedModality}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }